import React, { useState, useEffect, useMemo } from 'react';
import AppLoading from '../views/components/app-loading/app-loading';
import { DEFAULT_SCHEME } from '../constants/constants';
import { ECompanyNames, ELoadingStatus } from '@http/enums';
import { v1 } from '@api/v1';

interface ISchemeContext {
  scheme?: string;
  schemeLoadingStatus: ELoadingStatus;
  isRoundFamily?: boolean;
  setScheme: (scheme: string) => void;
}

export const SchemeContext = React.createContext<ISchemeContext>({
  scheme: undefined,
  schemeLoadingStatus: ELoadingStatus.Idle,
  setScheme: () => {},
});

export const SchemeProvider = (props: { children: React.ReactNode }) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const [scheme, setScheme] = useState<string | undefined>(undefined);
  const [schemeLoadingStatus, setSchemeLoadingStatus] = useState<ELoadingStatus>(
    ELoadingStatus.Idle,
  );

  const setSchemeToApp = (scheme: string) => {
    document.documentElement.dataset.scheme = scheme;
    localStorage.setItem('scheme', scheme);
    setScheme(scheme);
  };

  const isRoundFamily = useMemo(() => {
    return (
      scheme === DEFAULT_SCHEME ||
      scheme === ECompanyNames.Demo ||
      scheme === ECompanyNames.DemoEnglish
    );
  }, [scheme]);

  useEffect(() => {
    const fetchAndStoreScheme = async () => {
      if (isDevelopment) {
        setSchemeToApp(process.env.REACT_APP_SCHEME!);
        setSchemeLoadingStatus(ELoadingStatus.Succeeded);
        return;
      }
      try {
        const response = await v1.organization.scheme.get();

        if (response?.errorCode) {
          await v1.logger.post({
            level: 'Error',
            message: `errorCode=${response.errorCode} errorMsg=${response.errorMsg}`,
          });
          if (response?.errorCode === 404 || response?.errorCode === 500) {
            setSchemeLoadingStatus(ELoadingStatus.Failed);
          }
          return;
        }

        const { code, version } = response;

        if (code) {
          setSchemeToApp(code); // Обновляем схему в localStorage и состоянии компонента
          setSchemeLoadingStatus(ELoadingStatus.Succeeded);
        }

        if (version) {
          localStorage.setItem('organizationVersion', version);
        }
      } catch (error) {
        console.error('Error fetching organization scheme:', error);
        setSchemeLoadingStatus(ELoadingStatus.Failed);
      }
    };

    fetchAndStoreScheme();
  }, []);

  // Если схема еще не загружена, показываем экран загрузки
  if (schemeLoadingStatus !== ELoadingStatus.Succeeded) {
    return <AppLoading />;
  }

  return (
    <SchemeContext.Provider
      value={{ scheme, schemeLoadingStatus, setScheme: setSchemeToApp, isRoundFamily }}
    >
      {props.children}
    </SchemeContext.Provider>
  );
};
